#HomeTop{
    background: url(../Images/HomeTop.jpg) top center no-repeat;
    margin-top: -40px;
    padding-bottom: 5px;
    .HeadBlock{
        background: white;
        margin-top: 170px;
        padding: 20px 20px;
        padding-bottom: 30px;
        position: relative;
        text-align: center;
        h2{
            font-size: 25px;
            font-weight: normal;
            text-transform: uppercase;
        }
        h4{
            font-size: 25px;
            font-weight: bold;
        }
        .HomeHeaderCube1{
            background: url(../Images/HomeHeaderCube1.png) no-repeat center center;
            width: 128px;
            height: 109px;
            position: absolute;
            top: -80px;
            left: -70px;
        }
        .HomeHeaderCube2{
            background: url(../Images/HomeHeaderCube2.png) no-repeat center center;
            width: 108px;
            height: 91px;
            position: absolute;
            bottom: -5px;
            right: 20px;
        }
        .registration-btn{
            background-color: #ed1c24;
            color: #ffffff;
            padding: 10px 15px;
            margin-top: 15px;
            display: inline-block;
            font-size: 20px;
            text-transform: uppercase;
        }
    }
    .SubHeadBlock{

        margin-top: 200px;
        margin-bottom: 30px;
        .block{
            text-align: center;

            .number{
                font-size: 80px;
                color: #3e3f42;
            }
            .text{
                color:  #939393;
                padding-top: 20px;
                border-top: 3px solid #e5ebef;
                text-transform: lowercase;
                font-size: 30px;
            }
        }
    }
}

#HomeTopRed{
    background: url(../Images/HomeTopRed.jpg) top center repeat-y;
    .RedBlock{
        padding-bottom: 100px;
        h2{
            color: #fff;
            margin-bottom: 100px;
            margin-top: 100px;
        }
        .block{
            text-align: center;
            .icon{
                margin: auto;
                width: 100px;
                height: 100px;

                background-image: url(../Images/HomeTopRed.png);
                background-repeat: no-repeat;
            }
            .text{
                color: #fff;
                font-size: 24px;
                margin-top: 5px;
            }
        }
        @media (max-width: 1199px){
            .block{
                margin-bottom: 30px;
            }
        }
        .block#Location .icon{
            background-position: 0px 0px;
        }
        .block#Calendar .icon{
            background-position: -100px 0px;
        }
        .block#Time .icon{
            background-position: -200px 0px;
        }
    }

}
#AboutUs{
    padding-bottom: 30px;
    background: url(../Images/HomeAboutUs.jpg) top center no-repeat;
    h2{
        margin-bottom: 40px;
        margin-top:80px;
    }
    p{
        font-size: 20px;
    }
    img{
        width: 100%;
    }
    @media (max-width: 979px){
        img{
            margin-bottom: 30px;
        }
    }
}

#Flickr{
    background-color: #212124;
    .block{
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

#News.List{

    background-image: url(../Images/NewsLeftBG.png), url(../Images/NewsRightBG.png);
    background-position: left center, right center;
    background-repeat: no-repeat;
}
#SingleNews{
    background-color: #f5f8fa;
    background-image: url(../Images/SingleNewsLeftBG.png), url(../Images/SingleNewsRightBG.png);
    background-position: left center, right center;
    background-repeat: no-repeat;
}
#News{


    .block{
        padding-bottom: 100px;
        .btn{
            margin-top: 80px;
        }
        h2{
            padding-bottom: 40px;
            padding-top: 80px;
        }

        .NewsItem{
            .Image{
                width: 100%;
            }
            .Meta{
                margin-top: 10px;
                color: #a4a4a4;
                font-size: 16px;

                .Author{
                    margin-left: 20px;
                }
            }
            .Text{
                margin-top: 30px;
                font-size: 24px;
                text-transform: uppercase;
            }
        }
    }
}
#CatalogLine{
    background-color: #ed1c24;
    padding-top:15px;
    padding-bottom:5px;
    .btn-white{
        color: white ;
        background: none ;
    }
    p{
        text-align: center;
        font-size: 20px;
        color: white;
    }
}

#Participants.Single{
    background-image: url(../Images/SingleNewsLeftBG.png), url(../Images/SingleNewsRightBG.png);
    background-position: left center, right center;
    background-repeat: no-repeat;
}
#Participants{
    padding-bottom: 100px;
    background-color: #f5f8fa;
    h2{
        margin-top: 90px;
        margin-bottom: 80px;
    }
    .btn{
        margin-top: 50px;
        margin-bottom: 80px;
    }
    .block{
        text-decoration: none;
        display: block;
        margin-top: 20px;
        text-align: center;
        vertical-align: middle;
        background: #fff;
        position: relative;
        .text{

            color: #ffffff;
            font: 20px  MuseoSansCyrl;
            display: none;
            width: 100%;
            height: 100%;
            background-color: #ed1c24;
            span{
                display: table-cell;

                vertical-align: middle;
            }
        }
        img{
            max-height: 90%;
            max-width: 90%;
            width: auto;
            height: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }


    }
    .block:hover .text{
        display: table;
    }
    .block:hover img{
        display: none;
    }
}
#Program{

    background-image: url(../Images/ProgramLeftBG.png), url(../Images/ProgramRightBG.png);
    background-position: left top, right bottom;
    background-repeat: no-repeat;
    background-color: #ffc600;
    h2{
        margin-top: 90px;
        margin-bottom: 80px;
    }
    .no-nav-tabs{
        border: none;
        :hover {
            text-decoration: none !important;
        }
    }

    .tab{
        font-size: 20px;
        color: #fff;
        border: 1px solid #fff;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;
        background: none;

    }
    .active{
        .tab{
            color: #000;
            background: #fff;
        }
    }

    .no-tab-content{
        margin-top: 10px;
        margin-bottom: 30px;

        font-size: 13px;

        .row{
            background: #ffdd66;
            border-bottom: 2px solid #ffc600;
            display: flex;
            .Time, .Company, .Text{
                padding: 10px;
            }
            .Time, .Company{
                text-align: center;
                border-right: 2px solid #ffc600;
            }
            .check-icon{
                background-image: url(../Images/check-icon.png);   
                width: 35px;
                height: 35px;
                /*margin-right: -70px;*/
                position: absolute;
                /*right: -40px;*/
                top: 50%;
                margin-top: -   17px;
                opacity: 0;
            }
            &:hover{
                opacity: .9;
            }
        }
        .row:nth-child(odd){
            background: #ffe380;
        }

        .row.Head{
            background: #ffeeb3;
        }
        .row.check{
            .check-icon{
                opacity: 1;
            }
        }
        .PresentationRow{
            cursor: pointer;
        }

    }

}
#ProgramRegistration{
    .form-group{
        label{

        }
    }
    .btn-default{
        background: white!important;
        width: 100%!important;
    }
}
#Registration{
    padding-bottom: 100px;
    background-color: #f5f8fa;
    background-image: url(../Images/RegistrationLeftBG.png), url(../Images/RegistrationRightBG.png);
    background-position: left center, right center;
    background-repeat: no-repeat;

    h2{
        margin-top: 90px;
        margin-bottom: 60px;
    }
    .block{
        .row{
            padding: 20px;
            padding-bottom: 20px;
            padding-top: 40px;
            position: relative;
            background: #fff;

        }
    }
    .price{
        text-align: center;
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 20px;
        clear: both;
    }
    .BtnBlock{
        position: absolute;
        width: 100%;
        bottom: -20px;
        left: 0px;
    }
    .row.Head{
        text-align: center;
        text-transform: uppercase;
        font-size: 24px;
        color: #fff;
        padding: 20px;
        min-height: 60px;
        span{
            text-transform: none;
            font-size: 18px;
        }
    }
    .black{
        .row.Head{
            background: #3e3f42;
        }
    }
    li{
        padding-bottom: 13px;
        font-size: 16px;
    }
    .red{
        .row.Head{
            background: #ed1c24;
        }
        ul li span{
            color: #ed1c24;
        }
    }
    ul{
        list-style: none;
        li{
            position: relative;
        }
        span{
            position: absolute;
            left: -25px;
            top: 2px;
        }
    }
    .yellow{
        .row.Head{
            background: #ffcb16;
            position: relative;

        }
        .DownArrow{
            padding: 0px;
            width: 0;
            height: 0;
            border-left: 191px solid transparent;
            border-right: 191px solid transparent;
            border-top: 30px solid #ffcb16;
        }

        ul li span{
            color: #ffcb16;
        }
    }
    .div-to-scroll{
        clear: both;
    }
    .RegForm{
        margin-top: 0px;
        background: #fff;
        padding: 20px;
        display: none;
        .form-control{
            border-radius: 0px;
        }
    }

    .RegForm.open{
        display: block;
    }
    .RegTitle{
        margin-top: 50px;
        padding-bottom: 20px;
        padding-top: 20px;
        display: none;
        h3{
            margin: auto;
            text-align: center;
            color: #fff;
        }
    }
    .RegTitle.open{
        display: block;
    }
    .RegTitle.black{
        background: #3e3f42;
    }
    .black{
        .price{
            color: #3e3f42;
        }

        ul li span{
            color: #3e3f42;
        }
    }
    .RegTitle.red{
        background: #ed1c24;
    }
    .red{
        .price{
            color: #ed1c24;
        }
    }
}
#Partners{
    padding-bottom: 100px;
    .Head{
        padding-bottom: 50px;
        padding-top: 50px;
        .text{
            min-height: 58px;
            text-transform: uppercase;
            text-align: center;
        }
        @media (max-width: 979px){
            .text{
                min-height: 0;
                margin-bottom: 10px;
            }
        }

        .image{
            text-align: center;
            img{
                max-width: 260px;
                max-height: 40px;
            }
            @media (max-width: 979px){
                img{
                    margin-bottom: 30px;
                }
            }
        }
    }
    h3{
        text-transform: uppercase;
        margin-top: 50px;
        margin-bottom: 40px;
    }
    .block{
        display: block;
        margin-top: 20px;
        text-align: center;
        vertical-align: middle;
        background: #fff;
        position: relative;
        border: 1px solid #eee;

        text-decoration: none;
        .text{

            color: #ffffff;
            font: 20px  MuseoSansCyrl;
            display: none;
            width: 100%;
            height: 100%;
            background-color: #ed1c24;
            span{
                display: table-cell;

                vertical-align: middle;
            }
        }
        img{
            max-height: 86%;
            max-width: 86%;
            width: auto;
            height: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    .block:hover .text{
        display: table;
    }
    .block:hover img{
        display: none;
    }
}

#Contacts{
    h2{
        padding-bottom: 50px;
        font-weight: bold;
        font-size: 30px;
    }
}
#ContactsForm{
    background-image: url(../Images/ContactLeftBG.png), url(../Images/ContactRightBG.png);
    background-position: left top, right bottom;
    background-repeat: no-repeat;

    padding-top: 50px ;
    padding-bottom: 50px ;
    background-color: #f5f8fa;
    h2{
        margin-top: 50px;
        margin-bottom: 100px;
    }
    h4{
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        font-size: 23px;
        font-weight: bold;
        margin-top: 80px;
        margin-bottom: 60px;
    }
    .Logo{
        text-align: left;
    }
    .Data{
        text-align: left;
        font-size: 26px;;
        color: #000;
        a{
            color: #000;
        }
    }
    form{
        text-align: left;
        label{
            text-align: left;
        }
    }
}

.xl-fix{
    margin: auto;
    max-width: 1170px;
}
#Archive{
    padding-bottom: 100px;
    h2{
        text-transform: uppercase;
        margin-top: 50px;
        margin-bottom: 40px;
    }
    .block{
        display: block;
        text-align: center;
        vertical-align: middle;
        background: #fff;
        position: relative;
        border: 1px solid #eee;
        width: 100%;
        height: 100%;

        text-decoration: none;

        background-color: #aaa;
        a{
            color: #ffffff;
            font: 20px  MuseoSansCyrl;
            display: table;
            width: 100%;
            height: 100%;
            text-decoration: none;
            span{
                display: table-cell;

                vertical-align: middle;
            }
        }

    }
    .block:hover{
        background-color: #ed1c24;
    }

}
.has-error {
    input{
        background: #ffe8e8!important;
    }
}
