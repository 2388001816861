#shema {
    margin: 10px 0;
    padding: 10px 0;
}

.container {
    margin: 0 auto;
}

#stands:before {
    content: '';
    display: block;
    /*0 padding: 50%;*/
}

#stands {
    position: relative;
}
#stands .stands-img{
    max-width: 100%;
}

#stands .stand {
    position: absolute;
    width:  1.90%;
    height: 0.95%;
    border: 1px solid black;
}

#stands .stand.vertical {

}

#stands .stand.type-1 {
    background: green;
}
#stands .stand.type-2 {
    background: darkblue;
}
#stands .stand.type-3 {
    background: red;
}
#stands .stand.type-1.status-2,
#stands .stand.type-2.status-2 {
    background: black;
}
#stands .stand.type-3 {
    background: red;
}
#stands .stand.status-1{
    cursor: pointer;
}
#stands .stand.status-1.red{
    background: red;
}
.stand-info{
    display: none;
    width: 150px;
    position: absolute;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow:    0px 1px 5px 0px rgba(50, 50, 50, 0.75);
    box-shadow:         0px 1px 5px 0px rgba(50, 50, 50, 0.75);
    border: solid 1px #cccccc;
    padding: 8px;
    z-index: 99;
    text-align: center;
}
.stand-info .title{
    font-weight: bold;
    background-color: #f7f7f7;
    border-bottom: solid 1px #eaeaea;
    margin: -8px -8px 5px;
    padding: 8px;
}
.stand-info .image-block{
    padding-bottom: 5px;
    min-height: 50px;
}
.stand-info  .stand-num{
    font-size: 0.8em;
    color: #888888;
}
.stand-info .image-block img{
    max-width: 100%;
}
/*
START STANDS BY NUMBER

horizontal: 1,2,3,4,5,6,7,8,9,10,11,12,13,14,23,24,25,26,27,46,48,49,50,51,54,55,56,57,59,60,65,66,70,71
vertical:
rotated:

 */

#stands .stand.number-1 {
    top: 44.9%;
    left: 41.8%;
}
#stands .stand.number-2 {
    top: 44.9%;
    left: 61.8%;
}
#stands .stand.number-3 {
    top: 44.9%;
    left: 64.8%;
}
#stands .stand.number-4 {
    top: 44.9%;
    left: 67.8%;
}
#stands .stand.number-5 {
    top: 48.25%;
    left: 70.1%;
    transform: rotate(90deg);
}
#stands .stand.number-6 {
    top: 51.7%;
    left: 67.8%;
}
#stands .stand.number-7 {
    top: 51.7%;
    left: 64.7%;
}
#stands .stand.number-8 {
    top: 51.7%;
    left: 61.8%;
}
#stands .stand.number-9 {
    top: 51.7%;
    left: 40.3%;
}
#stands .stand.number-10 {
    top: 51.7%;
    left: 37.3%;
}
#stands .stand.number-11 {
    top: 51.7%;
    left: 34.3%;
}
#stands .stand.number-12 {
    top: 44.9%;
    left: 32.8%;
}
#stands .stand.number-13 {
    top: 44.9%;
    left: 35.8%;
}
#stands .stand.number-14 {
    top: 44.9%;
    left: 38.8%;
}
#stands .stand.number-15 {
    top: 40.0%;
    left: 46.0%;
    transform: rotate(90deg);
}
#stands .stand.number-16 {
    top: 37.9%;
    left: 43.6%;
    transform: rotate(90deg);
}
#stands .stand.number-17 {
    top: 35.7%;
    left: 41.2%;
    transform: rotate(90deg);
}
#stands .stand.number-18 {
    top: 33.8%;
    left: 38.7%;
    transform: rotate(90deg);
}
#stands .stand.number-19 {
    top: 30.1%;
    left: 38.7%;
    transform: rotate(90deg);
}
#stands .stand.number-20 {
    top: 26.6%;
    left: 38.7%;
    transform: rotate(90deg);
}
#stands .stand.number-21 {
    top: 23.0%;
    left: 38.7%;
    transform: rotate(90deg);
}
#stands .stand.number-22 {
    top: 19.4%;
    left: 38.7%;
    transform: rotate(90deg);
}
#stands .stand.number-23 {
    top: 16.6%;
    left: 44.0%;
}
#stands .stand.number-24 {
    top: 16.6%;
    left: 47.5%;
}
#stands .stand.number-25 {
    top: 16.6%;
    left: 51.1%;
}
#stands .stand.number-26 {
    top: 16.6%;
    left: 54.7%;
}
#stands .stand.number-27 {
    top: 16.6%;
    left: 58.2%;
}
#stands .stand.number-28 {
    top: 19.4%;
    left: 63.5%;
    transform: rotate(90deg);
}
#stands .stand.number-29 {
    top: 23.0%;
    left: 63.5%;
    transform: rotate(90deg);
}#stands .stand.number-30 {
     top: 26.6%;
     left: 63.5%;
     transform: rotate(90deg);
 }
#stands .stand.number-31 {
    top: 30.2%;
    left: 63.5%;
    transform: rotate(90deg);
}
#stands .stand.number-32 {
    top: 33.8%;
    left: 63.5%;
    transform: rotate(90deg);
}
#stands .stand.number-33 {
    top: 35.7%;
    left: 61.0%;
    transform: rotate(90deg);
}
#stands .stand.number-34 {
    top: 37.9%;
    left: 58.6%;
    transform: rotate(90deg);
}
#stands .stand.number-35 {
    top: 40.0%;
    left: 56.2%;
    transform: rotate(90deg);
}
#stands .stand.number-36 {
    top: 35.1%;
    left: 48.3%;
    transform: rotate(90deg);
}

#stands .stand.number-37 {
    top: 35.1%;
    left: 53.9%;
    transform: rotate(90deg);
}
#stands .stand.number-38 {
    top: 31.5%;
    left: 48.3%;
    transform: rotate(90deg);
}

#stands .stand.number-39 {
    top: 31.5%;
    left: 53.9%;
    transform: rotate(90deg);
}
#stands .stand.number-40 {
    top: 27.9%;
    left: 48.3%;
    transform: rotate(90deg);
}

#stands .stand.number-41 {
    top: 27.9%;
    left: 53.9%;
    transform: rotate(90deg);
}
#stands .stand.number-42 {
    top: 24.3%;
    left: 48.3%;
    transform: rotate(90deg);
}

#stands .stand.number-43 {
    top: 24.3%;
    left: 53.9%;
    transform: rotate(90deg);
}
#stands .stand.number-44 {
    top: 20.7%;
    left: 48.3%;
    transform: rotate(90deg);
}
#stands .stand.number-45 {
    top: 20.7%;
    left: 53.9%;
    transform: rotate(90deg);
}
#stands .stand.number-46 {
    top: 37.6%;
    left: 29.3%;
}
#stands .stand.number-47 {
    top: 35.2%;
    left: 30.8%;
    transform: rotate(90deg);
}
#stands .stand.number-48 {
    top: 32.8%;
    left: 29.3%;
}#stands .stand.number-49 {
     top: 31.8%;
     left: 26.6%;
 }#stands .stand.number-50 {
      top: 31.8%;
      left: 23.8%;
  }#stands .stand.number-51 {
       top: 31.8%;
       left: 20.9%;
   }
#stands .stand.number-52 {
    top: 34.3%;
    left: 18.5%;
    transform: rotate(90deg);
}
#stands .stand.number-53 {
    top: 37.2%;
    left: 19.5%;
    transform: rotate(90deg);
}
#stands .stand.number-54 {
    top: 39.6%;
    left: 20.8%;
}
#stands .stand.number-55 {
    top: 39.6%;
    left: 23.8%;
}
#stands .stand.number-56 {
    top: 44.0%;
    left: 23.6%;
}
#stands .stand.number-57 {
    top: 44.0%;
    left: 20.9%;
}
#stands .stand.number-58 {
    top: 47.0%;
    left: 18.7%;
    transform: rotate(90deg);
}
#stands .stand.number-59 {
    top: 49.9%;
    left: 20.8%;
}
#stands .stand.number-60 {
    top: 49.9%;
    left: 23.6%;
}
#stands .stand.number-61 {
    top: 53.4%;
    left: 19.3%;
    transform: rotate(90deg);
}
#stands .stand.number-62 {
    top: 56.2%;
    left: 19.3%;
    transform: rotate(90deg);
}
#stands .stand.number-63 {
    top: 59.3%;
    left: 19.3%;
    transform: rotate(90deg);
}
#stands .stand.number-64 {
    top: 62.9%;
    left: 15.5%;
    transform: rotate(135deg);
}
#stands .stand.number-65 {
    top: 66.2%;
    left: 16.4%;
}
#stands .stand.number-66 {
    top: 66.2%;
    left: 20.2%;
}
#stands .stand.number-67 {
    top: 62.9%;
    left: 25.7%;
    transform: rotate(90deg);
}
#stands .stand.number-68 {
    top: 59.7%;
    left: 25.7%;
    transform: rotate(90deg);
}
#stands .stand.number-69 {
    top: 56.7%;
    left: 25.7%;
    transform: rotate(90deg);
}
#stands .stand.number-70 {
    top: 68.4%;
    left: 19.2%;
}
#stands .stand.number-71 {
    top: 68.4%;
    left: 15.4%;
}
#stands .stand.number-72 {
    top: 70.1%;
    left: 12.5%;
    transform: rotate(135deg);
}
#stands .stand.number-73 {
    top: 72.4%;
    left: 10.2%;
    transform: rotate(135deg);
}
#stands .stand.number-74 {
    top: 74.7%;
    left: 7.9%;
    transform: rotate(135deg);
}
#stands .stand.number-75 {
    top: 77.9%;
    left: 8.0%;
    transform: rotate(45deg);
}
#stands .stand.number-76 {
    top: 80.7%;
    left: 10.8%;
    transform: rotate(45deg);
}
#stands .stand.number-77 {
    top: 81.1%;
    left: 14.7%;
    transform: rotate(175deg);
}
#stands .stand.number-78 {
    top: 78.9%;
    left: 17.0%;
    transform: rotate(135deg);
}
#stands .stand.number-79 {
    top: 76.4%;
    left: 19.5%;
    transform: rotate(135deg);
}
#stands .stand.number-80 {
    top: 73%;
    left: 23%;
    transform: rotate(135deg);
}

/*
END STANDS BY NUMBER
 */
.selected-stand-text input{
    width: 50px;
    padding-left: 5px;
    padding-right: 5px;
    border: none;
    border-bottom: dotted 1px #cccccc;
    text-align: center;
}