#News{
    padding-bottom: 50px;
    padding-top: 50px;
    h3{
        margin-top: 0px;
        font-family: Roboto;
        font-size: 22px;
        font-weight: 700;
        a{
            color: #000;
            text-transform: uppercase;
        }
    }
    .Time{
        font-size: 16px;
        color: #a4a4a4;
    }
    .Content{
        font-size: 16px;
    }
    .Image{
        overflow: hidden;

        img {
            width: 100%;            
        }
    }
}