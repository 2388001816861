#Footer{
    background-color: #212124;
    padding-top: 25px;
    padding-bottom: 25px;
    .ContactData{
        font-size: 18px;
        color: rgba(255, 255, 255, 0.3);
        font-family: MuseoSansCyrl;
        line-height: 60px;
        text-align: center;
        .mail{
            font-size: 16px;
        }
        span{
            color: #fff;
            padding-right: 5px;
        }
        a{
            color: rgba(255, 255, 255, 0.3);
        }
        .SocialIcon{
            
        span{
            padding-top: 2px;
            padding-right: 0px;
        }
            a{
                padding: 2px;
                line-height: 10px !important;
                text-align: center;
                color: #fff;
                border: 1px solid #fff;
                height: 26px;
                width: 26px;
                display: inline-block;
            }
        }
    }
}